import { createRef } from 'react';
import { Vector3 } from 'three';

const state = {
  sections: 39,
  pages: 38,
  zoom: 75,
  paragraphs: [
    {
      offset: 1,
      factor: 1.55,
      header: 'Root',
      image: '/imgs/1.jpg',
      aspect: 1.5,
      text: 'Mill Valley | There was a big tree...',
    },
    {
      offset: 2,
      factor: 2.0,
      header: 'Body',
      image: '/imgs/2.jpg',
      aspect: 1.5,
      text: 'Mill Valley | A body, but that was not all...',
    },
    {
      offset: 3,
      factor: 2.0,
      header: 'Flower',
      image: '/imgs/3.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Also flowers...',
    },
    {
      offset: 4,
      factor: 2.0,
      header: 'Flower Again',
      image: '/imgs/4.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Many many flowers...',
    },
    {
      offset: 5,
      factor: 1.25,
      header: 'Again',
      image: '/imgs/5.jpg',
      aspect: 1.5,
      text: 'Mill Valley | All dancing...',
    },
    {
      offset: 7,
      factor: 1.05,
      header: 'You Know',
      image: '/imgs/7.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Like your hair',
    },
    {
      offset: 8,
      factor: 1.75,
      header: 'Sky',
      image: '/imgs/6.jpg',
      aspect: 1.5,
      text: 'Silver Lake | LA Skyline',
    },
    {
      offset: 9,
      factor: 1.75,
      header: 'Blue',
      image: '/imgs/8.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Always',
    },
    {
      offset: 10,
      factor: 1.25,
      header: 'Core',
      image: '/imgs/9.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Just above hell',
    },
    {
      offset: 11,
      factor: 1.5,
      header: 'Path',
      image: '/imgs/10.jpg',
      aspect: 1.5,
      text: 'Mill Valley | No one may follow',
    },
    {
      offset: 12,
      factor: 1.25,
      header: 'Red',
      image: '/imgs/11.jpg',
      aspect: 1.5,
      text: 'Mill Valley | You',
    },
    {
      offset: 13,
      factor: 1.75,
      header: 'Stone',
      image: '/imgs/12.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Me',
    },
    {
      offset: 14,
      factor: 1.6,
      header: 'Window',
      image: '/imgs/14.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Wide open',
    },
    {
      offset: 15,
      factor: 1.4,
      header: 'Hawk',
      image: '/imgs/15.jpg',
      aspect: 1.5,
      text: 'Reseda | Vigilant',
    },
    {
      offset: 16,
      factor: 1.1,
      header: 'Destination',
      image: '/imgs/17.jpg',
      aspect: 1.5,
      text: 'Reseda | Unknown',
    },
    {
      offset: 17,
      factor: 1.4,
      header: 'Died',
      image: '/imgs/24.jpg',
      aspect: 1.5,
      text: 'Reseda | Multiple times',
    },
    {
      offset: 18,
      factor: 1.1,
      header: 'Luck',
      image: '/imgs/19.jpg',
      aspect: 1.5,
      text: 'Reseda | Not needed',
    },
    {
      offset: 19,
      factor: 1.0,
      header: 'Shining',
      image: '/imgs/21.jpg',
      aspect: 1.5,
      text: 'Mill Valley | In darkness',
    },
    {
      offset: 20,
      factor: 1.5,
      header: 'Jungle',
      image: '/imgs/22.jpg',
      aspect: 1.5,
      text: 'Mill Valley | No escape',
    },
    {
      offset: 21,
      factor: 1.9,
      header: 'Floating',
      image: '/imgs/26.jpg',
      aspect: 1.5,
      text: 'Mill Valley | Numb',
    },
    {
      offset: 22,
      factor: 1.5,
      header: 'City',
      image: '/imgs/28.jpg',
      aspect: 1.5,
      text: 'Griffith Park | Right here',
    },
    {
      offset: 23,
      factor: 1.8,
      header: 'City Hall',
      image: '/imgs/29.jpg',
      aspect: 1.5,
      text: 'DTLA | So high',
    },
    {
      offset: 24,
      factor: 1.2,
      header: 'Cold Storage',
      image: '/imgs/30.jpg',
      aspect: 1.5,
      text: 'Art District | So cold',
    },
    {
      offset: 25,
      factor: 1.6,
      header: 'Mmmerica',
      image: '/imgs/31.jpg',
      aspect: 1.5,
      text: 'DTLA | Yeehaw',
    },
    {
      offset: 26,
      factor: 1.4,
      header: '46',
      image: '/imgs/32.jpg',
      aspect: 1.5,
      text: 'Art District | So cool',
    },
    {
      offset: 27,
      factor: 1.7,
      header: 'R.I.P',
      image: '/imgs/33.jpg',
      aspect: 1.5,
      text: 'Art District | You',
    },
    {
      offset: 28,
      factor: 1.7,
      header: '<3',
      image: '/imgs/34.jpg',
      aspect: 1.5,
      text: 'Art District | AL LA',
    },
    {
      offset: 29,
      factor: 1.5,
      header: 'Wanna?',
      image: '/imgs/36.jpg',
      aspect: 1.5,
      text: 'Griffith Park | Get famous?',
    },
    {
      offset: 30,
      factor: 1.8,
      header: 'Oh',
      image: '/imgs/37.jpg',
      aspect: 1.5,
      text: 'DTLA | Honey!',
    },
    {
      offset: 31,
      factor: 1.6,
      header: 'Sky',
      image: '/imgs/38.jpg',
      aspect: 1.5,
      text: 'Griffith Park | LA Skyline',
    },
    {
      offset: 32,
      factor: 1.9,
      header: 'White Rabbit',
      image: '/imgs/39.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Matrix',
    },
    {
      offset: 33,
      factor: 1.6,
      header: "It's",
      image: '/imgs/40.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Showtime',
    },
    {
      offset: 34,
      factor: 1.6,
      header: 'Astro',
      image: '/imgs/41.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Above',
    },
    {
      offset: 35,
      factor: 1.6,
      header: 'To See',
      image: '/imgs/42.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Nothing',
    },
    {
      offset: 36,
      factor: 1.6,
      header: "What's Up",
      image: '/imgs/43.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Man',
    },
    {
      offset: 37,
      factor: 1.9,
      header: 'From',
      image: '/imgs/44.jpg',
      aspect: 1.5,
      text: 'West Hollywood | Streets',
    },
  ],
  stripes: [
    { offset: 0, color: '#000', height: 13 },
    { offset: 11.3, color: '#000', height: 20 },
  ],
  diamonds: [
    { x: 0, offset: 0.15, pos: new Vector3(), scale: 12, factor: 4 },
    { x: 6, offset: 1, pos: new Vector3(), scale: 1, factor: 2.1 },
    { x: -6, offset: 2.0, pos: new Vector3(), scale: 1.2, factor: 2.5 },
    { x: 6, offset: 3.0, pos: new Vector3(), scale: 1.2, factor: 1.95 },
    { x: -6, offset: 4.1, pos: new Vector3(), scale: 1.1, factor: 2.5 },
    { x: 0, offset: 5.75, pos: new Vector3(), scale: 1.25, factor: 0.85 },
    { x: -6, offset: 7, pos: new Vector3(), scale: 1.2, factor: 2 },
    { x: 6, offset: 8, pos: new Vector3(), scale: 1.1, factor: 2.1 },
    { x: -7, offset: 9, pos: new Vector3(), scale: 1.1, factor: 4.1 },
    { x: 6, offset: 10, pos: new Vector3(), scale: 1.1, factor: 2.1 },
    { x: -7, offset: 11, pos: new Vector3(), scale: 1.1, factor: 3.1 },
    { x: 6, offset: 12, pos: new Vector3(), scale: 1.1, factor: 2.3 },
    { x: -7, offset: 13, pos: new Vector3(), scale: 1.1, factor: 2.9 },
    { x: 6, offset: 14, pos: new Vector3(), scale: 1.2, factor: 1.3 },
    { x: -7, offset: 15, pos: new Vector3(), scale: 1.1, factor: 1.9 },
    { x: 7, offset: 16, pos: new Vector3(), scale: 1.9, factor: 2.3 },
    { x: -3, offset: 17, pos: new Vector3(), scale: 1.3, factor: 1.6 },
    { x: 7, offset: 18, pos: new Vector3(), scale: 1.4, factor: 1.9 },
    { x: -7, offset: 19, pos: new Vector3(), scale: 1.3, factor: 1.4 },
    { x: 7, offset: 20, pos: new Vector3(), scale: 1.1, factor: 1.9 },
    { x: -6, offset: 21, pos: new Vector3(), scale: 1.2, factor: 1.4 },
    { x: 7, offset: 22, pos: new Vector3(), scale: 1.3, factor: 1.5 },
    { x: -6, offset: 23, pos: new Vector3(), scale: 1.3, factor: 1.8 },
    { x: 6, offset: 23.7, pos: new Vector3(), scale: 1.5, factor: 1.5 },
    { x: -6, offset: 25, pos: new Vector3(), scale: 1.8, factor: 1.6 },
    { x: 5, offset: 26.1, pos: new Vector3(), scale: 1.2, factor: 1.9 },
    { x: -6, offset: 27, pos: new Vector3(), scale: 1.1, factor: 2.6 },
    { x: 5, offset: 27.9, pos: new Vector3(), scale: 1.1, factor: 1.9 },
    { x: -6, offset: 29, pos: new Vector3(), scale: 1.2, factor: 1.6 },
    { x: 5, offset: 30, pos: new Vector3(), scale: 1.1, factor: 1.6 },
    { x: -6, offset: 31, pos: new Vector3(), scale: 1.2, factor: 1.6 },
    { x: 7, offset: 32, pos: new Vector3(), scale: 1.2, factor: 1.9 },
    { x: -6, offset: 33, pos: new Vector3(), scale: 1.1, factor: 1.4 },
    { x: 7, offset: 34, pos: new Vector3(), scale: 1.1, factor: 1.6 },
    { x: -6, offset: 35, pos: new Vector3(), scale: 1.1, factor: 1.4 },
    { x: 7, offset: 36, pos: new Vector3(), scale: 1.1, factor: 1.8 },
    { x: -6, offset: 37, pos: new Vector3(), scale: 1.1, factor: 1.9 },
    { x: 0, offset: 38, pos: new Vector3(), scale: 2.5, factor: 6 },
  ],
  top: createRef(),
};

export default state;
